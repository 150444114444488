import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./index.css";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Activate from "./components/Activate";
import WorkFlow24 from "./components/WorkFlow24";
import FeedbackForm from "./components/FeedbackForm";
import TermsAndConditions from "./components/TermsAndConditions";
import ResetPassword from "./components/ResetPassword";
import VizioSignUp from "./components/VizioSignUp";

const Home: React.FC = () => {
  return (
    <div
      className="flex justify-center items-center h-screen bg-[#385363] bg-cover bg-center"
      style={{ backgroundImage: "url('/images/bg.png')" }}
    >
      <img src="/images/logo.png" alt="App Logo" className="max-w-xs" />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/Activate" element={<Activate />} />
        <Route path="/WorkFlow24" element={<WorkFlow24 />} />
        <Route path="/VizioSignUp" element={<VizioSignUp />} />
        <Route path="/Feedback" element={<FeedbackForm />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />

        <Route path="/terms*" element={<Navigate to="/TermsAndConditions" />} />
        <Route path="/Terms*" element={<Navigate to="/TermsAndConditions" />} />
        <Route path="/Terms" element={<Navigate to="/TermsAndConditions" />} />
        <Route path="/terms" element={<Navigate to="/TermsAndConditions" />} />
        <Route
          path="/TermsAndCondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/TermsConditions"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/TermsCondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsandconditions"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsandcondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsconditions"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termscondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsandcond"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/terms_cond"
          element={<Navigate to="/TermsAndConditions" />}
        />

        <Route
          path="/privacypolicy"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route
          path="/PrivacyPolicy*"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route path="/privacy" element={<Navigate to="/PrivacyPolicy" />} />
        <Route
          path="/privacypolicy"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route path="/Privacy" element={<Navigate to="/PrivacyPolicy" />} />
        <Route
          path="/privacy_statement"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route
          path="/privacy_terms"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route path="/priv_policy" element={<Navigate to="/PrivacyPolicy" />} />
        <Route
          path="/privacy_policy"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route
          path="/policystatement"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route
          path="/TermsAndCondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/TermsConditions"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/TermsCondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsandconditions"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsandcondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsconditions"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termscondition"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/termsandcond"
          element={<Navigate to="/TermsAndConditions" />}
        />
        <Route
          path="/terms_cond"
          element={<Navigate to="/TermsAndConditions" />}
        />

        <Route
          path="/privacypolicy"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route
          path="/PrivacyPolicy*"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route path="/privacy" element={<Navigate to="/PrivacyPolicy" />} />
        <Route
          path="/privacypolicy"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route path="/Privacy" element={<Navigate to="/PrivacyPolicy" />} />
        <Route
          path="/privacy_statement"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route
          path="/privacy_terms"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route path="/priv_policy" element={<Navigate to="/PrivacyPolicy" />} />
        <Route
          path="/privacy_policy"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route
          path="/policystatement"
          element={<Navigate to="/PrivacyPolicy" />}
        />
        <Route path="/policy" element={<Navigate to="/PrivacyPolicy" />} />
      </Routes>
    </Router>
  );
};

export default App;
