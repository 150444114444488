// src/components/FeedbackForm.tsx
import React, { useState } from "react";
import { ref, push } from "firebase/database";
import { database } from "src/firebase";
import { useSearchParams } from "react-router-dom";
import axios, { AxiosRequestHeaders, GenericAbortSignal } from "axios";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [confPassword, setConfPassword] = useState<string>("");
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});
  const [searchParams] = useSearchParams();

  // Retrieve specific query parameters
  const uid = searchParams.get("uid");
  const prt = searchParams.get("prt");

  // Simple validation regex patterns
  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

  const validateForm = () => {
    let formErrors: { password?: string; confirmPassword?: string } = {};

    if (!passwordRegex.test(password)) {
      formErrors.password =
        "Password must be 8 characters long, including Uppercase, Lowercase and Number";
    }

    if (password !== confPassword) {
      formErrors.confirmPassword = "Confirm Password must match the Password.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // True if no errors
  };

  const submitResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (
      password &&
      passwordRegex.test(password) &&
      confPassword &&
      confPassword === password
    ) {
      const url = `${process.env.REACT_APP_API_URL}/api/forget_password_confirm/${uid}/${prt}/`;

      // Create FormData object
      const formData = new FormData();
      formData.append("new_password1", password);
      formData.append("new_password2", confPassword);

      try {
        // Make POST request
        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Required to indicate form data
          },
        });

        if (response.status === 200) {
          setPasswordChanged(true);
          setPassword("");
          setConfPassword("");
        } else {
          alert("Something went wrong");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Something went wrong");
      }
    }
    // Validate form before submitting
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 p-6">
      {passwordChanged ? (
        <p className="text-center text-[2.5vh] font-bold mt-[1vh]">
          Thank you for resetting your password.
        </p>
      ) : (
        <form
          onSubmit={submitResetPassword}
          className="max-w-xl w-full mx-auto bg-white p-6 rounded-lg shadow-md"
        >
          <div className="flex justify-center">
            <img
              src="/images/splash_image.png"
              alt="Splash"
              className="w-48 h-24 object-contain"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Password:
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={`w-full px-4 py-2 border ${
                errors.password ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.password && (
              <p className="text-red-500 text-sm">{errors.password}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Confirm Password:
            </label>
            <input
              id="password"
              type="password"
              value={confPassword}
              onChange={(e) => setConfPassword(e.target.value)}
              required
              className={`w-full px-4 py-2 border ${
                errors.confirmPassword ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Reset Password
          </button>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;
