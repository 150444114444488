import React from "react";

const WorkFlow24: React.FC = () => {
  const appStoreLink =
    "https://apps.apple.com/in/app/screen-gallery/id6479288924";
  ("_blank");
  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.screengallery.dev&hl=en";
  ("_blank");
  const handleDivClick = (link: string) => {
    window.open(link);
  };

  return (
    <>
      <style>
        {`
          :root {
              --primaryColor: #385363;
              --primaryColorLight: #415e6f;
              --secondaryColor: #1f333f;
              --secondaryColorLight: #253b48;
              --lightTextColor: #e1e1e1;
              --darkTextColor: #010101;
              --accentColorLight: #afd3db;
              --accentColor: #73cddc;
              --dividerColor: rgba(225, 225, 225, 0.2);
              --transparentColor: #ffffff00;
          }

          body {
              margin: 0;
              overflow-y: scroll;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              height: 100vh;
              background-color: var(--primaryColor);
              font-family: "Raleway", sans-serif;
              word-wrap: break-word;
              letter-spacing: 0.5px;
          }

          .description {
              color: var(--lightTextColor);
              font-size: 18px;
              font-weight: 400;
              text-align: center;
              width: 90%;
              word-wrap: break-word;
          }

           .headertext {
              color: var(--lightTextColor);
              font-size: 34px;
              font-weight: 400;
              text-align: center;
              width: 90%;
              word-wrap: break-word;
          }
          .screen-title {
              color: var(--accentColorLight);
              font-size: 20px;
              font-weight: 400;
              text-align: center;
              width: 100%;
              word-wrap: break-word;
          }

          #splash-logo {
              opacity: 1;
              position: relative;
              scale: 1;
              height:80px;
              
          }

          #splash-screen {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 20px;
          }

          h1 {
              text-align: center;
          }

          .screen {
              margin-bottom: 20px;
          }

          .screen h2 {
              margin-top: 0;
          }

          .row {
              display: flex;
              width: 100%;
              align-items: center;
              margin-bottom: 20px;
          }

          .column {
              width: 90%;
              padding: 10px;
          }

          .description1 {
              order: 1;
          }

          .image {
              order: 2;
              text-align: center;
          }

          .description2 {
              order: 2;
          }

          .image2 {
              order: 1;
              text-align: center;
          }

          .img-play {
              width: 15%;
              // max-height: 500px;
              object-fit: contain;
              border-radius: 8px;
          }

          .image img {
              width: 100%;
              // max-height: 500px;
              object-fit: contain;
              border-radius: 8px;
          }

          @media (max-width: 768px) {
              .row {
                  flex-direction: column;
              }

              .column {
                  width: 100%;
              }

              .image img {
                  width: 100%;
                  height: auto;
              }
          }
        `}
      </style>

      <div id="splash-screen" className="bg-img">
        <div className="headertext mt-10 mb-10">
          <p>Welcome to Screen Gallery</p>
        </div>
        <img
          id="splash-logo"
          className="mb-10"
          src="/images/logo.png"
          alt="App Logo"
        />
        <div className="description">
          <p>
            Free with no credit card required. To scan the QR code setup your TV
            with your mobile device. (Apple or Android) This will enable you to
            have full control of your TV Screen Gallery from the easy user
            interface. *(basic service is free, upgrade to the premium service).
            {/* Please follow below steps from install application to QR code scan. */}
          </p>
        </div>
        {/* <div className="row">
          <div className="column description1">
            <p className="screen-title">1. Register yourself</p>
          </div>
          <div className="column image">
            <img src="/images/register.png" alt="Register" />
          </div>
        </div>
        <div className="row">
          <div className="column image2 image">
            <img src="/images/home.png" alt="Home" />
          </div>
          <div className="column description2">
            <p className="screen-title">
              2. Go to the Home page click on the right side Hamburger icon
            </p>
          </div>
        </div>
        <div className="row">
          <div className="column description1">
            <p className="screen-title">
              3. In side menu click on Connected Devices.
            </p>
          </div>
          <div className="column image">
            <img src="/images/sidebar.png" alt="Sidebar" />
          </div>
        </div>
        <div className="row">
          <div className="column image2 image">
            <img src="/images/connectdevice.png" alt="Connect Device" />
          </div>
          <div className="column description2">
            <p className="screen-title">4. Click on Add new Device button.</p>
          </div>
        </div>
        <div className="row">
          <div className="column description1">
            <p className="screen-title">5. Scan QR code</p>
          </div>
          <div className="column image">
            <img src="/images/scan.png" alt="Scan QR" />
          </div>
        </div> */}

        <div className="row w-[100%] items-center justify-center mt-10">
          <div className="description2">
            <p className="screen-title">Download app</p>
          </div>
          <div
            className="image img-play"
            onClick={() => handleDivClick(appStoreLink)}
          >
            <img src="/images/app_store.png" alt="App Store" />
          </div>
          <div
            className="image img-play"
            onClick={() => handleDivClick(playStoreLink)}
          >
            <img src="/images/play_store.png" alt="Play Store" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkFlow24;
