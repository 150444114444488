import React, { useEffect, useState } from "react";

const TermsAndConditions: React.FC = () => {
  const [content, setContent] = useState<string>("Loading content...");

  useEffect(() => {
    let apiUrl = process.env.REACT_APP_API_URL + "/api/terms_condition/";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setContent(data[0].description);
        } else {
          setContent("No content available.");
        }
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
        setContent("Error loading content.");
      });
  }, []);

  return (
    <div style={styles.container}>
      <div
        style={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    width: "90%",
    maxWidth: "800px",
    margin: "0px auto",
  },
  content: {
    lineHeight: "1.6",
    color: "#333",
  },
};

export default TermsAndConditions;
